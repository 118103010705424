const color_variants = {
  black: false, dark: false, light: false, white: false,
  primary: true, link: true, info: true, success: true, warning: true, danger: true,
};

export const TAG_COLORS = Object.entries(color_variants).flatMap(([color, canBeLight]) => {
  const sets = [
    {
      id: color,
      label: color,
      classes: [`is-${color}`],
    },
  ];
  if (canBeLight) {
    sets.push({
      id: `light-${color}`,
      label: `light ${color}`,
      classes: [`is-${color}`, 'is-light'],
    });
  }
  return sets;
});

export function colorProps(colorName) {
  return TAG_COLORS.find(e => e.id === colorName);
}
